export const VERSIONS_STATES = {
  draft: {
    tag: 'light-orange',
    label: { pt: 'Rascunho', en: 'Draft' }
  },
  review: {
    tag: 'medium',
    label: { pt: 'Em revisão', en: 'Under review' }
  },
  closed: {
    tag: 'light-gray',
    label: { pt: 'Bloqueado', en: 'Locked' }
  },
  published: {
    tag: 'light-primary',
    label: { pt: 'Publicado', en: 'Published' }
  },
  deleted: {
    tag: 'light-blue',
    label: { pt: 'Eliminado', en: 'Deleted' }
  },
  archived: {
    tag: 'light-blue',
    label: { pt: 'Arquivado', en: 'Archive' }
  }
}
