<template>
  <div class="h-full flex overflow-hidden panel-doc">
    <SidebarDocsList
      v-if="showLeftSidebar"
      ref="sidebarDocs"
      :page-key="pageKey"
      :context="context"
      :context-key="contextKey"
      :inject-payload="docsListPayload"
      :validations="docsListValidations"
      :show-record-actions="showRecordActions"
      :collapsable="collapsableDocsList"
      class="border-r border-gray-200"
      :get-documents-endpoint="getDocumentsEndpoint"
      @open-document="$emit('open', $event)"
      @new-document="openDocModal($event, null)"
      @edit-document="openDocModal(null, $event)"
      @edit-content="$emit('edit', $event)"
    />

    <section v-if="pageKey" class="relative flex-1 h-full w-full overflow-auto">
      <div v-if="!editMode" class="p-1.5 text-xs bg-gray-100 text-center font-medium text-black/50">
        Modo de leitura
      </div>
      <div v-else class="p-1.5 text-xs text-center text-white/80 font-medium" style="background-color: #ed884a">
        Modo de edição
      </div>
      <div
        class="relative"
        :class="{
          'py-5 px-0': !paddingless && !translationMode,
          'max-w-4xl mx-auto': isOriginal || !translationMode,
          'px-5': translationMode
        }"
      >
        <div
          class="backdrop-blur-lg absolute left-0 top-0 bottom-0 right-0 z-10 transition-all opacity-0 pointer-events-none"
          :class="{
            'opacity-100': loading,
            'opacity-0': !loading
          }"
        ></div>
        <div v-if="loading" class="w-full max-w-4xl mx-14">
          <div class="bg-white h-64 rounded mt-20"></div>
        </div>
        <PanelDocumentPreview
          v-else-if="page && !editMode && currentVersion"
          :ref="`editor_${versionKey}`"
          :show-header="isOriginal || !translationMode"
          :page="page"
          :users="users"
          :version="currentVersion"
          :show-title="showTitle"
          :permissions="permissions"
          :show-comments-highlight="showCommentsHighlight"
          :borderless="borderless"
          :comments="comments"
          :original-key="originalKey"
          @sidebar="setSidebar($event)"
          @open="$emit('open', $event)"
          @edit="$emit('edit', $event)"
          @set-active-block="setActiveBlock($event)"
          @new-language="createNewLanguage()"
          @selection-update="selectionUpdate"
          @selected="selectedCommand = $event"
          @title-changed="updateTitle($event)"
          @new-comment="newComment"
          @document-loaded="documentLoaded"
        />
        <PanelDocumentEdit
          v-else-if="page && editMode && currentVersion && permissions"
          :ref="`editor_${versionKey}`"
          :is-r-t-l="isRTL"
          :show-title="showTitle"
          :version-key="versionKey"
          :original-key="originalKey"
          :current-version="currentVersion"
          :page-key="pageKey"
          :page="page"
          :users-editing="usersEditing"
          :users="users"
          :active-block="activeBlock"
          :comments-document="comments['document']"
          :comments="comments"
          :show-chapters="false"
          :show-header="!translationMode"
          :format-options="formatOptions"
          :editor-blocks="editorBlocks"
          :permissions="permissions"
          :show-comments-highlight="showCommentsHighlight"
          :borderless="borderless"
          :show-preview-button="page.application != 'courses'"
          @sidebar="setSidebar($event)"
          @preview="$emit('preview', page)"
          @open="$emit('open', $event)"
          @edit="$emit('edit', $event)"
          @remove-content="removeBlock"
          @restore-block="restoreBlock"
          @change-order="changeBlockOrder"
          @set-active-block="setActiveBlock($event)"
          @file-uploaded="addFile"
          @files-uploaded="addFiles"
          @file-deleted="removeFile"
          @new-language="createNewLanguage()"
          @selection-update="selectionUpdate"
          @selected="selectedCommand = $event"
          @title-changed="updateTitle($event)"
          @new-comment="newComment"
          @document-loaded="documentLoaded"
          @editor-ready="editor = $event"
        ></PanelDocumentEdit>
      </div>
    </section>
    <fw-panel-info v-else centered class="flex-1 h-full">
      Selecione um documento para visualizar ou editar
    </fw-panel-info>

    <div v-if="pageKey" :key="`document_sidebar_${versionKey}_${pageKey}_${editMode}`" class="w-[20rem]">
      <SidebarEditor
        v-if="page"
        :key="versionKey"
        :myrole="myrole"
        :versions="page.versions"
        :editable="editable"
        :active-commands="activeCommands"
        :selected-command="selectedCommand"
        :active-block="activeBlock"
        :active-block-type="activeBlockType"
        :editor="editor"
        :editor-command="editorCommand"
        :sidebar="sidebar"
        :current-version="currentVersion"
        :translator="translator"
        :comments-list="commentsList"
        :comments="comments"
        :users="users"
        :page="page"
        :permissions="permissions"
        :flow-users="flowUsers"
        :allowed-tabs="isMondaecus ? ['editor', 'comments', 'settings', 'translations'] : allowedEditorTabs"
        :sidebar-key="sidebarKey"
        :format-options="formatOptions"
        @set-active-block="setActiveBlock($event)"
        @tab="setSidebar($event)"
        @change-version="changeVersion($event)"
        @new-language="createNewLanguage()"
        @import-file="importFile"
        @new-comment="newComment"
        @open-comment="openComment($event)"
        @delete-comment="deleteComment"
        @edit-comment="editComment"
        @export-file="exportDocument"
        @generate-pdf="generatePdf"
        @snapshot="createSnapshot($event)"
        @publish="publishVersion($event)"
        @share-document="modal = 'share'"
        @change-state="changePageState($event, versionKey)"
        @delete-document="deleteDocument()"
        @delete-version="deleteVersion()"
        @flow-update="flowUpdate"
      />
    </div>
    <b-modal
      :active="modal == 'new-language'"
      :width="400"
      scroll="keep"
      trap-focus
      aria-role="dialog"
      aria-modal
      :can-cancel="true"
      custom-class="rounded-buefy-modal z-10 bg-white bg-opacity-50 backdrop-blur-sm"
      style="z-index: 10"
      @close="closeModal"
    >
      <ModalNewLanguage
        v-if="page != null"
        :used-languages="usedLanguages"
        :page-key="page.key"
        @success="appendNewVersion"
        @close="closeModal"
      ></ModalNewLanguage>
    </b-modal>
    <fw-modal :active="modal == 'comment'" confirm-close paddingless @close="closeModal">
      <BlockEditPost
        v-if="modal == 'comment'"
        :collection-key="commentsCollectionKey"
        :parent-post-key="null"
        :post="activeComment"
        :type="'comment'"
        :allow-files="false"
        :metadata="activeCommentMetadata"
        @comment-saved="commentSaved($event)"
        @close="closeModal"
      />
    </fw-modal>
    <fw-modal :active="modal == 'share'" confirm-close paddingless @close="closeModal">
      <ModalShare
        v-if="modal == 'share'"
        :page-key="pageKey"
        :translate-mode="translator"
        :myrole="myrole"
        :version-key="versionKey"
        :is-share-link-active="page.options.allow_public_url"
        @close="closeModal"
      />
    </fw-modal>
    <fw-modal :active="modal == 'flow-users'" confirm-close size="min" width="54rem" @close="closeModal">
      <ModalPageFlowUsers
        v-if="modal == 'flow-users'"
        :users="flowUsers"
        :flow="selectedFlow"
        :endpoint-search="endpointSearchFlowUsers"
        @close="closeModal"
        @flow-update="flowUpdate"
      />
    </fw-modal>

    <fw-modal
      v-if="showNewPageModal"
      :active.sync="showNewPageModal"
      :can-cancel="true"
      size="min"
      @close="closeDocModal"
    >
      <template #default>
        <ModalNewPage
          v-if="!selectedDocument"
          :context="context"
          :context-key="contextKey"
          :inject-payload="{ parent_key: parentPage?.key }"
          :redirect-after-create="false"
          :show-languages="false"
          @created="handleNewPage"
          @close="closeDocModal"
        ></ModalNewPage>
        <ModalEditPage
          v-else
          :data="selectedDocument"
          :show-languages="false"
          @close="closeDocModal"
          @updated="handleDocUpdated"
        ></ModalEditPage>
      </template>
    </fw-modal>
  </div>
</template>

<script>
import ServicePages from '@/fw-modules/fw-core-vue/pages/services/ServicePages'
import Vue from 'vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServicePosts from '@/fw-modules/fw-core-vue/posts/services/ServicePosts'
import { VERSIONS_STATES } from '@/fw-modules/fw-core-vue/pages/utils/index.js'

export default {
  components: {
    PanelDocumentPreview: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelDocumentPreview.vue'),
    SidebarDocsList: () => import('@/fw-modules/fw-core-vue/pages/components/sidebars/SidebarDocsList.vue'),
    PanelDocumentEdit: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelDocumentEdit.vue'),
    SidebarEditor: () => import('@/fw-modules/fw-core-vue/pages/components/sidebars/SidebarEditor.vue'),
    BlockEditPost: () => import('@/fw-modules/fw-core-vue/posts/components/blocks/BlockEditPost'),
    // SidebarBlockDetail: () => import('@/fw-modules/fw-core-vue/pages/components/sidebars/SidebarBlockDetail.vue'),
    ModalShare: () => import('@/fw-modules/fw-core-vue/pages/components/modals/ModalShare'),
    ModalNewPage: () => import('@/fw-modules/fw-core-vue/pages/components/modals/ModalNewPage'),
    ModalEditPage: () => import('@/fw-modules/fw-core-vue/pages/components/modals/ModalEditPage'),
    ModalPageFlowUsers: () => import('@/fw-modules/fw-core-vue/pages/components/modals/ModalPageFlowUsers'),
    ModalNewLanguage: () => import('@/fw-modules/fw-core-vue/pages/components/modals/ModalNewLanguage.vue')
  },
  props: {
    pageKey: {
      type: String,
      default: null
    },
    propVersionKey: {
      type: String,
      default: null
    },
    editMode: {
      type: Boolean,
      default: true
    },
    paddingless: {
      type: Boolean,
      default: false
    },
    borderless: {
      type: Boolean,
      default: false
    },
    showLeftSidebar: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    context: {
      type: String,
      default: null
    },
    contextKey: {
      type: String,
      default: null
    },
    editorBlocks: {
      type: Array,
      default: () => [
        'heading',
        'image',
        'files',
        'video',
        'youtube',
        'blockquote',
        'codeBlock',
        'bulletList',
        'orderedList',
        'files',
        'horizontalRule'
      ]
    },
    formatOptions: {
      type: Array,
      default: () => [
        'bold',
        'italic',
        'underline',
        'strike',
        'link',
        'blockquote',
        'code',
        'codeBlock',
        'bulletList',
        'orderedList',
        'heading',
        'textAlign',
        'highlight',
        'small'
      ]
    },
    docsListPayload: {
      type: Object,
      default: () => {}
    },
    docsListValidations: {
      type: Object,
      default: () => {
        return {
          can_create: true,
          can_show_deleted: true
        }
      }
    },
    collapsableDocsList: {
      type: Boolean,
      default: true
    },
    showRecordActions: {
      type: Boolean,
      default: true
    },
    allowedEditorTabs: {
      type: Array,
      default: () => ['editor', 'versions', 'comments', 'settings'] // , 'translations'
    },
    // getDocumentFlow, updateDocumentFlow, getUsersForDocumentFlow
    flowEndpoints: {
      type: Object,
      default: null
    },
    getDocumentsEndpoint: {
      type: Function,
      default: null
    }
  },

  data() {
    return {
      showNewPageModal: false,
      selectedDocument: null,
      selectedFlow: null,
      parentPage: null,
      current: {},
      users: {},
      flowUsers: {},
      page: null,
      myrole: null,
      loading: false,
      loadingComments: false,
      currentVersion: null,
      errorSaving: false,
      savingData: false,
      dirtyBlocks: [],
      activeBlock: null,
      modal: null,
      sidebar: 'versions',
      //Real time presence status
      realtimeDebouncer: null,
      selectedCommand: 'paragraph',
      usersEditing: {},
      titleDebouncer: null,
      activeCommentMetadata: null,
      activeComment: null,
      activeCommands: {},
      comments: {}, //comments will be stored in a dictionary with the block id as key
      commentsList: [], //list from more recent to older
      totalComments: 0,
      sidebarKey: 0,
      permissions: {
        read: false,
        write: false,
        delete: false,
        comment: false,
        share: false,
        update_status: false,
        import_file: false,
        export_file: false,
        generate_pdf: false
      },
      versionsStates: Object.freeze(VERSIONS_STATES),
      editor: null,
      showCommentsHighlight: false,
      commentTimer: null
    }
  },

  computed: {
    isOriginal() {
      return this.currentVersion && this.currentVersion.is_original
    },
    translationMode() {
      return this.isMondaecus && this.currentVersion && !this.currentVersion.is_original
    },
    isMondaecus() {
      return process.env.VUE_APP_KEY == 'mondaecus'
    },
    isRTL() {
      if (!this.currentVersion) {
        return false
      }
      let languagesRtl = ['ar', 'he', 'fa']
      let languageCode = this.currentVersion.language.split('-')[0]
      return this.currentVersion && languagesRtl.includes(languageCode)
    },
    language() {
      console.log('this.$i18n.locale :>> ', this.$i18n.locale)
      return this.$store.state.language ?? this.$i18n.locale
    },
    commentsCollectionKey() {
      return this.currentVersion && this.currentVersion.collections.length > 0
        ? this.currentVersion.collections[0].key
        : null
    },
    originalKey() {
      let original = this.page.versions.find(v => v.is_default)
      console.log('original', original)
      return original ? original.key : null
    },
    usedLanguages() {
      return this.page.versions.map(v => v.language)
    },
    wsMessages() {
      return this.$store.state.session.unreadExamWsMessages
    },
    socketId() {
      return this.$store.state.socket.connectionId
    },
    activeBlockType() {
      if (this.currentVersion == null || this.currentVersion.blocks == null || this.currentVersion.blocks.length == 0)
        return null
      if (this.activeBlock >= this.currentVersion.blocks.length) {
        return null
      }
      if (this.activeBlock >= 0) {
        return this.currentVersion.blocks[this.activeBlock].type
      } else {
        return null
      }
    },
    /*activeBlockStyle() {
      return this.currentVersion.blocks[this.activeBlock]?.content?.style ?? 'h1'
    },*/
    expandedRightSidebar() {
      return this.sidebar != null
    },
    versionKey() {
      if (this.propVersionKey) {
        return this.propVersionKey
      }
      if (this.page && this.page.versions && this.page.versions.length > 0) {
        return this.page.versions[0].key
      }
      return null
    },
    editable() {
      return this.editMode && this.currentVersion?.state == 'draft' && this.permissions.write
    },
    translator() {
      return this.$route.meta.translator
    },
    status() {
      return this.currentVersion?.state || 'draft' // this.page?.state || 'draft'
    },
    publishedVersion() {
      return this.page?.versions?.find(v => v.state == 'published')
    }
  },
  watch: {
    wsMessages(newMessages) {
      if (this.realtimeDebouncer == null && newMessages && newMessages.length > 0) {
        this.realtimeDebouncer = setTimeout(() => {
          console.log('editorWSMessages changed', newMessages)
          //change user state
          for (let index = 0; index < newMessages.length; index++) {
            const message = newMessages[index]
            console.log('newMessage', message)
            if (message.type == 'editorOnlineUsers' && message.data.length > 0) {
              let currentVersionUsers = message.data.filter(element => element.page_version_key == this.versionKey)
              if (currentVersionUsers.length > 0) {
                console.log('currentVersionUsers', currentVersionUsers[0].users)
                if (Array.isArray(currentVersionUsers[0].users)) {
                  this.$store.dispatch('setConnectedUsers', { page: 'editor', users: currentVersionUsers[0].users })
                } else {
                  this.$store.dispatch('setConnectedUsers', {
                    page: 'editor',
                    users: Object.keys(currentVersionUsers[0].users).map(key => {
                      let userData = currentVersionUsers[0].users[key]
                      userData['key'] = key
                      return userData
                    })
                  })
                }
              }
            } else if (message.type == 'editorUserBlockSelect') {
              Vue.set(this.usersEditing, message.page_version_key + ':' + message.block_key, message.user)
              //this.usersEditing[message.page_version_key + ':' + message.block_key] = message.user
            } else if (message.type == 'editorUserBlocUnselect') {
              if (this.usersEditing[message.page_version_key + ':' + message.block_key].key == message.user.key) {
                //this.usersEditing[message.page_version_key + ':' + message.block_key] = null
                Vue.set(this.usersEditing, message.page_version_key + ':' + message.block_key, null)
              }
            } else if (message.type == 'editorPostDelete') {
              //comment deleted
              let post = message['comment'] ? message['comment'] : message['post']
              console.log('editorPostDelete', post)
              this.deleteComment(post)
            } else if (message.type == 'editorNewComment') {
              //comment created
              let post = message['comment'] ? message['comment'] : message['post']
              let user = message.user
              if (user && user.key) {
                this.users[user.key] = user
              }
              console.log('editorNewComment', post)
              this.insertComment(post, true)
            } else if (message.type == 'editorPostEdit') {
              //comment edited
              let post = message['comment'] ? message['comment'] : message['post']
              console.log('editorPostEdit', post)
              this.insertComment(post)
            } else if (message.type == 'editorNewPageVersion') {
              //new page version created
              console.log('editorNewPageVersion', message)
              this.page.versions.push(message.version)
            } else if (message.type == 'editorStateUpdate') {
              console.log('editorStateUpdate', message)
              //page state updated
              let version = this.page.versions.find(element => element.key == message.page_version_key)
              if (version) {
                version.state = message.state
              }
              if (this.currentVersion && this.currentVersion.key == message.page_version_key) {
                this.currentVersion.state = message.state
              }
            }
          }
          this.$store.commit('removeFromExamMessageQueue', newMessages.length)
          this.realtimeDebouncer = null
        }, 1000)
      }
    },
    socketId(newSocketId) {
      if (newSocketId != null) {
        this.subscribeEditor(this.versionKey)
      }
    },
    versionKey(newVal, oldVal) {
      console.log('version key changed', { newVal, oldVal })
      if (oldVal) {
        this.unsubscribeEditor(oldVal)
      }
      if (newVal && newVal != oldVal) {
        this.subscribeEditor(newVal)
        //reset values:
        this.activeBlock = null
        this.connectedUsers = []
        this.comments = {}
        this.commentsList = []
        this.dirtyBlocks = []
        this.activeCommentMetadata = null
        this.activeComment = null
        this.loadPageVersion()
      }
    },

    pageKey(newVal, oldVal) {
      console.log('pageKey changed', { newVal, oldVal })
      if (newVal && newVal != oldVal) {
        this.currentVersion = null
        this.page = null
        this.myrole = null
        this.users = {}
        this.loadPageContent()
      }
    }
  },
  beforeMount() {
    this.loadPageContent()
  },
  beforeDestroy() {
    this.unsubscribeEditor(this.versionKey)
  },
  methods: {
    appendNewVersion(newVersionData) {
      this.page.versions.push(newVersionData.version)
      this.users = newVersionData.users
      this.$router.push({
        name: 'content-pages-translator',
        params: { key: this.pageKey, version: newVersionData.version.key }
      })
      this.closeModal()
    },
    openDocModal(parentPage, doc) {
      console.log({ parentPage, doc })
      this.showNewPageModal = true
      this.selectedDocument = doc
      this.parentPage = parentPage
    },

    closeDocModal() {
      this.showNewPageModal = false
      this.selectedDocument = null
      this.parentPage = null
    },

    handleNewPage(data) {
      this.$emit('edit', data)
      this.closeDocModal()
      this.$refs.sidebarDocs.forceUpdate()
    },

    handleDocUpdated(data) {
      this.$emit('open', data)
      this.closeDocModal()
      this.$refs.sidebarDocs.forceUpdate()
    },
    setCommentsNumber(nodeId, commentsCount) {
      this.$refs['editor_' + this.versionKey].setCommentsNumber(nodeId, commentsCount)
    },
    deleteDocument() {
      this.$buefy.dialog.confirm({
        title: this.$t('delete_document_title'),
        message: this.$t('delete_document_message'),
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: async () => {
          try {
            let result = await ServicePages.deletePage(this.pageKey)
            console.log('deletePage', result)
            this.$emit('deleted', result)
            this.$buefy.toast.open({
              message: this.$t('document_deleted'),
              type: 'is-success',
              position: 'is-bottom-right'
            })
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    deleteVersion() {
      this.$buefy.dialog.confirm({
        title: this.$t('delete_version_title'),
        message: this.$t('delete_version_message'),
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: async () => {
          try {
            let result = await ServicePages.deletePageVersion(this.pageKey, this.versionKey)
            console.log('deletePageVersion', result)
            this.page.versions = this.page.versions.filter(element => element.key != this.versionKey)
            this.$emit('deleted-version', result)
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    createNewLanguage() {
      console.log('createNewLanguage')
      //verify if original language has state closed
      let originalVersion = this.page.versions.find(element => element.is_original)
      if (originalVersion.state != 'draft') {
        this.modal = 'new-language'
      } else {
        this.$buefy.dialog.confirm({
          title: this.$t('warning_title'),
          message: this.$t('warning_message'),
          confirmText: this.$t('warning_confirm'),
          cancelText: this.$t('warning_cancel'),
          onConfirm: async () => {
            //lock original version
            let result = await ServicePages.changePageState(this.pageKey, originalVersion.key, 'closed')
            console.log('changePageState', result)
            this.modal = 'new-language'
          }
        })
      }
    },
    changePageState(state, versionKey = null) {
      this.$buefy.dialog.confirm({
        title: this.$t('change_state_title'),
        message: this.$t('change_state_message') + ' ' + state + '?',
        confirmText: this.$t('change_state_confirm'),
        cancelText: this.$t('change_state_cancel'),
        onConfirm: async () => {
          try {
            let result = await ServicePages.changePageState(this.pageKey, versionKey, state)
            console.log('changePageState', result)
            //change state of version
            this.$set(this.currentVersion, 'state', state)

            if (state == 'published') {
              for (const version of this.page.versions) {
                if (versionKey == version.key) version.state = state
                else if (version.state == 'published') version.state = 'archived'
              }
            }
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    openComment(comment) {
      //scroll to the block,
      //highlight the block
      console.log('openComment', comment)

      this.showCommentsHighlight = true
      //just for 5 seconds
      if (this.commentTimer) clearTimeout(this.commentTimer)
      this.commentTimer = setTimeout(() => {
        this.showCommentsHighlight = false
        this.commentTimer = null
      }, 3000)

      if (this.$refs['editor_' + this.versionKey]) {
        this.$refs['editor_' + this.versionKey].focusEditorNode(comment.metadata.blockId)
      }

      if (comment.metadata && comment.metadata.blockId) {
        this.scrollToBlock(comment.metadata.blockId)
      }
    },
    scrollToBlock(id) {
      console.log('scrollToBlock', id)
      //scroll smothly to element with the id
      let element = document.getElementById(id)
      let container = document.querySelector('.inner-main-content')
      if (element) {
        //console.log('scrolling to element', element)
        container.scrollTo({
          top: element.offsetTop - 100,
          behavior: 'smooth'
        })
      } else {
        let possibleElem = document.querySelectorAll("[data-id='" + id + "']")
        if (possibleElem.length > 0) {
          container.scrollTo({
            top: possibleElem[0].offsetTop - 100,
            behavior: 'smooth'
          })
        } else {
          this.$buefy.snackbar.open({
            message: 'O bloco já não existe',
            type: 'is-warning',
            position: 'is-top',
            actionText: 'Ok'
          })
        }
      }
    },
    documentLoaded() {
      Vue.nextTick(() => {
        this.prepareInlineCommentsNumber()
      })
    },
    prepareInlineCommentsNumber() {
      console.log('prepareInlineCommentsNumber ====')
      //get all dom elements with the class block_comments
      let blockComment = document.querySelector('.block_comments')
      let parent = document.querySelector('.comments-header-wrapper')
      if (blockComment && parent) {
        blockComment.innerHTML = this.totalComments
        //add class
        parent.classList.add('opacity-100')
        //remove class
        parent.classList.remove('opacity-0')
      }
      //}
    },
    async loadAllComments() {
      this.loadingComments = true
      this.comments = {}
      this.comments['document'] = []
      let result = await this.loadComments(1)
      console.log('loadAllComments', result)
      if (result) {
        let totalPages = result.pagination.total_pages
        if (totalPages > 1) {
          for (let index = 2; index <= totalPages; index++) {
            await this.loadComments(index)
          }
        }
      }

      //await 1000 seconds to load all comments
      await new Promise(resolve => setTimeout(resolve, 2000))
      this.prepareInlineCommentsNumber()
      this.loadingComments = false
    },
    async loadComments(page = 1) {
      if (this.currentVersion.collections.length > 0) {
        console.log('load comments', this.currentVersion.collections)
        try {
          let data = await ServicePosts.getCollectionPosts(this.currentVersion.collections[0].key, 'comment', {
            page: page,
            limit: 50
          })
          console.log('loadComments :>> ', data)
          let comments = data.posts
          this.users = { ...this.users, ...data.users }
          this.totalComments = data.pagination.total_items
          comments.forEach(comment => {
            if (this.comments[comment.metadata.blockId] == null) {
              this.comments[comment.metadata.blockId] = []
            }
            //verify if comment is already in the list
            let index = this.comments[comment.metadata.blockId].findIndex(element => element.key == comment.key)
            if (index == -1) {
              this.comments[comment.metadata.blockId].push(comment)
              this.commentsList.push(comment)
            }
          })
          return data
        } catch (e) {
          console.error('loadComments', e)
        }
      }
    },
    async generatePdf({ pageKey, versionKey }) {
      await ServicePages.exportPageToPDF(pageKey, versionKey)
      this.$buefy.toast.open({
        message: this.$t('pdf_generated'),
        type: 'is-success'
      })

      this.currentVersion.pdf_file_status = 'start'
      const version = this.page.versions.find(v => v.key == versionKey)
      if (version) version.pdf_file_status = 'start'
    },
    exportDocument(format) {
      console.log('exportDocument :>> ', format)
      let targetLanguage = this.page.versions.find(element => element.key == this.versionKey).language
      let title = this.page.title
      switch (format) {
        case 'xliff': {
          if (this.translator) {
            let originalDocument = this.$refs[`editor_${this.versionKey}`]?.$refs[
              `page_content_${this.versionKey}`
            ].getDocument('json')
            let targetDocument = this.$refs[`editor_${this.versionKey}`]?.$refs[
              `page_content_${this.versionKey}`
            ].getDocument('json')
            let xlf = utils.generateXLIFF(originalDocument, targetDocument, '2.0')
            let blob = new Blob([xlf], { type: 'application/xml' })
            utils.downloadFileFromBlob(blob, title + '-' + targetLanguage + '.xlf')
          } else {
            this.$buefy.toast.open({
              message: this.$t('warning_xliff'),
              type: 'is-danger',
              position: 'is-bottom-right'
            })
          }
          break
        }
        case 'text': {
          let text = this.$refs[`editor_${this.versionKey}`]?.$refs[`page_content_${this.versionKey}`].getDocument(
            'text'
          )
          let blob = new Blob([text], { type: 'text/plain' })
          utils.downloadFileFromBlob(blob, title + '-' + targetLanguage + '.txt')
          break
        }
        case 'html': {
          let html = this.$refs[`editor_${this.versionKey}`]?.$refs[`page_content_${this.versionKey}`].getDocument(
            'html'
          )

          let blob = new Blob([html], { type: 'text/html' })
          utils.downloadFileFromBlob(blob, title + '-' + targetLanguage + '.html')
          break
        }
        default:
          break
      }
    },
    newComment(blockId = 'document') {
      this.activeComment = null
      this.activeCommentMetadata = {
        blockId: blockId
      }
      this.modal = 'comment'
    },
    commentSaved(comment) {
      console.log('commentSaved', comment)
      let blockId = comment.metadata.blockId
      let updateNumber = false
      if (this.activeComment == null) {
        //its a new comment, add it to list and map
        if (!this.comments[blockId]) {
          Vue.set(this.comments, blockId, [])
        }
        this.comments[blockId].unshift(comment)
        //add it to first position
        this.commentsList.unshift(comment)
        updateNumber = true
      } else {
        //replace content in list and map
        let index = this.comments[blockId].findIndex(element => element.key == comment.key)
        let indexList = this.commentsList.findIndex(element => element.key == comment.key)
        if (index != -1) {
          Vue.set(this.comments[blockId], index, comment)
        }
        if (indexList != -1) {
          Vue.set(this.commentsList, indexList, comment)
        }
      }
      this.sidebarKey++
      //this.prepareInlineCommentsNumber()
      //update comments number
      this.activeComment = null
      this.closeModal()
      if (updateNumber) {
        this.setCommentsNumber(blockId, this.comments[blockId].length)
      }
    },
    insertComment(comment, isNewComment = false) {
      console.log('commentSaved', comment)
      if (isNewComment) {
        //verify if comment is already in the list
        if (this.commentsList.some(element => element.key == comment.key)) {
          console.log('comment already exists')
          return
        }
        let blockId = comment.metadata.blockId
        //its a new comment, add it to list and map
        if (!this.comments[blockId]) {
          Vue.set(this.comments, blockId, [])
        }
        this.comments[blockId].unshift(comment)
        //add it to first position
        this.commentsList.unshift(comment)
        this.setCommentsNumber(blockId, this.comments[blockId].length)
      } else {
        //replace content in list and map
        let blockId = comment.metadata.blockId
        let index = this.comments[blockId].findIndex(element => element.key == comment.key)
        let indexList = this.commentsList.findIndex(element => element.key == comment.key)
        if (index != -1) {
          Vue.set(this.comments[blockId], index, comment)
        }
        if (indexList != -1) {
          Vue.set(this.commentsList, indexList, comment)
        }
      }
      this.sidebarKey++
      //this.prepareInlineCommentsNumber()
      this.closeModal()
    },
    deleteComment(comment) {
      console.log('deleteComment', comment)
      let blockId = comment.metadata.blockId
      let index = this.comments[blockId].findIndex(element => element.key == comment.key)
      let indexList = this.commentsList.findIndex(element => element.key == comment.key)
      if (index != -1) {
        Vue.delete(this.comments[blockId], index)
      }
      if (indexList != -1) {
        Vue.delete(this.commentsList, indexList)
      }
      //this.prepareInlineCommentsNumber()
      this.setCommentsNumber(blockId, this.comments[blockId].length)
    },
    editComment(comment) {
      this.activeCommentMetadata = comment.metadata
      this.activeComment = comment
      this.modal = 'comment'
    },
    saveComment(comment) {
      console.log('saveComment', comment)
      this.activeComment = null
      this.closeModal()
    },
    importFile(file) {
      console.log('importing file', file)
      if (file.name.split('.').pop() == 'xlf') {
        let self = this
        let reader = new FileReader()
        reader.readAsText(file, 'UTF-8')
        reader.onload = async function() {
          let data = utils.parseXLIFF(reader.result)
          console.log('processed', data)
          let processedData = utils.createTiptapDocumentFromXLIFF(data)
          let originalDocument = processedData.document
          let comments = processedData.comments
          let targetDocument = utils.createTiptapDocumentFromXLIFF(data, 'target').document
          //verify if target language is created
          let targetLanguage = data[0].segments[0].targetLanguage
          console.log('targetLanguage', targetLanguage)
          if (self.page) {
            //verify if the target language is already created
            let existingVersion = self.page.versions.find(
              element => element.language.toLowerCase() == targetLanguage.toLowerCase()
            )
            if (existingVersion) {
              //open the version
              self.$router.push({
                // TODO: probably need to change this, we are now in a component
                name: 'content-pages-translator',
                params: { key: self.page.key, version: existingVersion.key }
              })
              //wait for the version to load
              //update the document
            } else {
              //create the version
              let newVersion = await ServicePages.createPageVersion(self.pageKey, targetLanguage)
              console.log('newVersion', newVersion)
              self.$router.push({
                // TODO: probably need to change this, we are now in a component
                name: 'content-pages-translator',
                params: { key: self.page.key, version: newVersion.version.key }
              })
            }
            setTimeout(() => {
              //self.editor.commands.setContent(targetDocument)
              console.log('ASYNC original', originalDocument, 'target', targetDocument, 'comments', comments)
              self.$refs['editor_' + self.versionKey].importDocument(originalDocument, targetDocument, comments)
            }, 3000)
          }
          //if not, create it
          //if created, update the document

          //to broadcast the new document:
          //self.editor.commands.setContent(originalDocument)
          console.log('original', originalDocument, 'target', targetDocument, 'comments', comments)
          //comments will be created in a normal way (the user that uploads the document is the author of all the comments)
        }
        reader.onerror = function(evt) {
          console.error('error reading file', evt)
        }
        this.$buefy.toast.open({
          message: this.$t('file_imported'),
          type: 'is-success',
          position: 'is-bottom-right'
        })
      } else {
        this.$buefy.toast.open({
          message: this.$t('warning_invalid_file'),
          type: 'is-danger',
          position: 'is-bottom-right'
        })
        return
      }
    },
    createDocumentFromXLIFF(data, type = 'source') {
      //TODO: Convert to tiptap document (original + target)
      let comments = []
      let document = {
        type: 'doc',
        content: []
      }
      for (let d = 0; d < data.length; d++) {
        //file
        if (data[d].notes) {
          //concat notes with comments
          comments.concat(data[d].notes)
        }
        for (let s = 0; s < data[d].segments.length; s++) {
          document.content.push({
            type: 'paragraph',
            attrs: {
              id: data[d].segments[s].unitId,
              groupId: data[d].segments[s].groupId ? data[d].segments[s].groupId : null
            },
            content: [
              {
                type: 'text',
                text: data[d].segments[s][type]
              }
            ]
          })
          //Add block notes
          comments.concat(
            data[d].segments[s].notes.map(note => {
              return {
                blockId: data[d].segments[s].unitId,
                content: note
              }
            })
          )
        }
      }
      return { document, comments }
    },
    updateTitle(title) {
      if (!this.permissions.write) {
        return
      }
      if (this.currentVersion) {
        this.currentVersion.title = title
      }
      //update versions
      if (this.page) {
        this.page.versions.forEach(element => {
          if (element.key == this.versionKey) {
            element.title = title
          }
        })
      }
      //debounce save title
      if (this.titleDebouncer) {
        clearTimeout(this.titleDebouncer)
      }
      this.titleDebouncer = setTimeout(async () => {
        let result = await ServicePages.updatePageTitle(this.pageKey, this.versionKey, this.currentVersion.title)
        console.log('updateTitle', result)
      }, 1000)
    },
    setSidebar(tab) {
      console.log('setSidebar', tab)
      this.sidebar = tab
    },
    async createSnapshot(eventKey) {
      try {
        const result = await ServicePages.createSnapshot(this.page.key, eventKey)
        console.log('createSnapshot', result)
        this.$buefy.toast.open({
          message: this.$t('snapshot_created'),
          type: 'is-success',
          position: 'is-bottom-right'
        })
        this.page.versions = [result, ...this.page.versions]
        //change version
        this.changeVersion(result)
      } catch (e) {
        console.error(e)
      }
    },
    publishVersion(versionKey) {
      this.changePageState('published', versionKey)
    },
    changeVersion(version) {
      this.$emit('change-version', version)
      //   if (!version.is_default) {
      //     this.$router.push({
      //       name: 'content-pages-translator',
      //       params: { key: this.page.key, version: version.key }
      //     })
      //   } else if (this.editable) {
      //     this.$router.push({
      //       name: 'content-pages-editor-with-version',
      //       params: { key: this.page.key, version: version.key }
      //     })
      //   } else {
      //     this.$router.push({
      //       name: 'content-pages-viewer-with-version',
      //       params: { key: this.page.key, version: version.key }
      //     })
      //   }
    },
    selectionUpdate(updated) {
      console.log('selectionUpdate', updated)
      this.activeCommands = updated
      //this.selectedCommand = updated.style ?? 'paragraph'
    },
    subscribeEditor(versionKey) {
      if (versionKey) {
        //Subscribe editor
        ServicePages.subscribeToPage('subscribe_editor', this.pageKey, versionKey, this.socketId)
      } else {
        console.error('Version key is required for subscribing to editor')
      }
    },
    unsubscribeEditor(versionKey) {
      //Unsubscribe editor
      ServicePages.subscribeToPage('unsubscribe_editor', this.pageKey, versionKey, this.socketId)
    },
    restoreBlock(index, block) {
      this.currentVersion.blocks.splice(index, 0, block)
    },
    removeBlock(index) {
      this.currentVersion.blocks.splice(index, 1)
    },
    changeBlockOrder(dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return

      const result = [...this.currentVersion.blocks]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      this.currentVersion.blocks = result
    },
    editorCommand(cmd) {
      console.log('editor command ' + cmd)
      if (!['meta', 'paragraph', 'codeblock', 'h1', 'h2', 'h3'].includes(cmd)) {
        //toggle command
        this.activeCommands[cmd] = !this.activeCommands[cmd]
      }
      this.$refs[`editor_${this.versionKey}`]?.$refs[`page_content_${this.versionKey}`].execCommand(
        this.currentVersion.blocks[this.activeBlock].key,
        cmd
      )
    },
    markDirty(blockKey) {
      if (!this.dirtyBlocks.includes(blockKey)) {
        this.dirtyBlocks.push(blockKey)
      }
    },
    setActiveBlock(blockObject) {
      console.log('Panel document, Set active block ', blockObject)
      this.activeBlock = blockObject
    },
    async addFile(blockIndex, file) {
      console.log('add file')
      if (this.currentVersion.blocks[blockIndex].files == null) {
        this.currentVersion.blocks[blockIndex].files = []
      }
      let type = this.currentVersion.blocks[blockIndex].type
      if (type == 'image' || type == 'video') {
        let result = await ServicePages.filesPreview([file.key])
        console.log('FILE PREVIEW', result)
        this.currentVersion.blocks[blockIndex].files = [result[0]]
      } else {
        this.currentVersion.blocks[blockIndex].files.push(file)
      }
    },
    async addFiles(blockIndex, files) {
      console.log('add file')
      if (this.currentVersion.blocks[blockIndex].files == null) {
        this.currentVersion.blocks[blockIndex].files = files
      }
      let type = this.currentVersion.blocks[blockIndex].type
      if (type == 'image' || type == 'video') {
        let result = await ServicePages.filesPreview(files.map(f => f.key))
        console.log('FILE PREVIEW', result)
        this.currentVersion.blocks[blockIndex].files.concat(files)
      } else {
        this.currentVersion.blocks[blockIndex].files.concat(files)
      }
    },
    removeFile(file) {
      var blockIndex = file.blockIndex
      //get file index from file key
      var fileIndex = this.currentVersion.blocks[blockIndex].files.findIndex(element => {
        return element.key == file.fileKey
      })
      console.log('remove file', fileIndex, file.blockIndex)
      this.currentVersion.blocks[blockIndex].files.splice(fileIndex, 1)
    },
    closeModal() {
      this.activeCommentMetadata = null
      this.modal = null
      this.selectedFlow = null
    },
    saveData() {
      //save document data
      this.savingData = true
      let updateTitle = false
      let blocks = JSON.parse(JSON.stringify(this.currentVersion.blocks))

      var isTitleDirty = this.dirtyBlocks.indexOf('title')
      if (isTitleDirty !== -1) {
        this.dirtyBlocks.splice(isTitleDirty, 1)
        updateTitle = true
      }
      let dirtyBlocks = blocks.filter(element => {
        return this.dirtyBlocks.includes(element.key)
      })

      let updatePromises = []

      if (updateTitle) {
        updatePromises.push(ServicePages.updatePageTitle(this.pageKey, this.versionKey, this.currentVersion.title))
      }
      if (this.dirtyBlocks.length > 0) {
        updatePromises.push(ServicePages.updatePageContentBlocksBulk(this.pageKey, this.versionKey, dirtyBlocks))
      }
      Promise.all(updatePromises)
        .then(result => {
          let newdata = updateTitle ? result[1] : result[0]
          console.log('saved', newdata)
          //replace block ids
          this.currentVersion.blocks.map(block => {
            //console.log('onld block key', block.key)
            if (newdata[block.key]) {
              //console.log('new block', newdata[block.key])
              let newkey = newdata[block.key].key
              block.key = newkey
            }
            //block.key = result[index].key
          })
          this.savingData = false
          this.errorSaving = false
          this.dirtyBlocks = []
        })
        .catch(e => {
          console.error(e)
          this.savingData = false
          this.errorSaving = true
        })
    },
    async loadPageVersion() {
      if (!this.versionKey || !this.pageKey) return
      try {
        this.loading = true
        let result = await ServicePages.getPageVersionContent(this.pageKey, this.versionKey)
        console.log('loadPageVersion > ', result)
        if (result.blocks == null) result.blocks = []
        result.blocks.sort((a, b) => a.index - b.index)
        if (result.version.title == null) result.version.title = ''
        this.currentVersion = result.version
        this.permissions = result.permissions

        // //change to translation mode if its not original
        // if (this.currentVersion.is_original == false && !this.translator) {
        //   this.$router.push({
        //     name: 'content-pages-translator',
        //     params: { key: this.pageKey, version: this.versionKey }
        //   })
        // }
        if (!result.permissions.write && this.editMode) {
          this.$emit('preview', this.page)
        }
        this.loadAllComments()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async getFlowData() {
      if (!this.flowEndpoints) {
        console.error('no api')
        return
      }

      if (!this.versionKey || !this.pageKey) return

      try {
        let result = await this.flowEndpoints.getDocumentFlow(this.pageKey, this.versionKey)
        console.log('getFlowData :>> ', result)
        if (result.flow) {
          this.$set(this.page, 'flow', result.flow)
          this.flowUsers = result.users
        }
      } catch (error) {
        console.error(error)
        return
      }
    },
    async loadPageContent() {
      if (!this.pageKey) return
      this.loading = true
      try {
        let pageVersions = await ServicePages.getPageVersions(this.pageKey)
        console.log('loadPageContent > ', pageVersions)
        this.page = pageVersions.page
        this.users = pageVersions.users
        this.myrole = pageVersions.user.role
        if (this.page.application) {
          this.getFlowData()
        }
        this.$emit('page-loaded', this.page)
        this.loadPageVersion()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async endpointSearchFlowUsers(params) {
      if (!this.flowEndpoints) {
        console.error('no api')
        return
      }
      return await this.flowEndpoints.getUsersForDocumentFlow(this.page.key, params)
    },
    flowUpdate(data) {
      console.log('flowUpdate :>> ', data)
      if (!data.flow) {
        console.error('Must have flow data')
        return
      }
      const newData = {}
      if (data.delete) {
        if (data.delete?.user_keys?.length) {
          newData.user_keys = data.flow.user_keys.filter(user => !data.delete.user_keys.includes(user))
        }
        if (data.delete?.roles?.length) {
          newData.user_roles = data.flow.user_roles.filter(role => !data.delete.roles.includes(role))
        }

        this.updateFlowData(data.flow.from_status, newData)
      }

      if (data.add === true) {
        this.selectedFlow = data.flow
        this.modal = 'flow-users'
      } else if (data.add?.user_keys?.length) {
        newData.user_keys = [...data.add.user_keys, ...(data.flow.user_keys ?? [])]
        this.updateFlowData(data.flow.from_status, newData)
      }
    },

    async updateFlowData(status, newData) {
      if (!this.flowEndpoints) {
        console.error('no api')
        return
      }
      await utils.tryAndCatch(this, async () => {
        const response = await this.flowEndpoints.updateDocumentFlow(this.page.key, {
          from_status: status,
          data: newData
        })
        this.$set(this.page, 'flow', response.flow)
        this.flowUsers = response.users
      })

      this.closeModal()
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {
    "pdf_generated": "PDF está a ser gerado",
    "delete_document_title": "Eliminar documento",
    "delete_document_message": "Tem a certeza que pretende eliminar este documento?",
    "document_deleted": "Documento movido para o trash com sucesso",
    "delete_version_title": "Eliminar tradução",
    "delete_version_message": "Tem a certeza que pretende eliminar esta tradução?",
    "confirm": "Confirmar",
    "cancel": "Cancelar",
    "change_state_title": "Alterar estado da página",
    "change_state_message": "Tem a certeza que pretende alterar o estado da página para",
    "change_state_confirm": "Sim",
    "change_state_cancel": "Cancelar",
    "warning_xliff": "Apenas no modo de tradução é que pode exportar documentos xliff",
    "warning_invalid_file": "Tipo de ficheiro inválido",
    "file_imported": "Ficheiro importado com sucesso",
    "warning_title": "Aviso",
    "warning_message": "Para criar uma tradução, é necessário que a versão original esteja bloqueada. Iremos bloquear a versão original. Deseja continuar?",
    "warning_confirm": "Continuar",
    "warning_cancel": "Cancelar",
    "snapshot_created": "Cópia de versão criada com sucesso"
  },
  "en": {
    "pdf_generated": "PDF is being generated",
    "delete_document_title": "Delete document",
    "delete_document_message": "Are you sure you want to delete this document?",
    "document_deleted": "Document moved to trash successfully",
    "delete_version_title": "Delete translation",
    "delete_version_message": "Are you sure you want to delete this translation?",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "change_state_title": "Change page state",
    "change_state_message": "Are you sure you want to change the page state to",
    "change_state_confirm": "Yes",
    "change_state_cancel": "Cancel",
    "warning_xliff": "Only in translation mode you can export xliff documents",
    "warning_invalid_file": "Invalid file type",
    "file_imported": "File imported successfully",
    "warning_title": "Warning",
    "warning_message": "To create a translation, the original version must be locked. We will lock the original version. Do you want to continue?",
    "warning_confirm": "Continue",
    "warning_cancel": "Cancel",
    "snapshot_created": "Version snapshot created successfully"
  }
}
</i18n>
